<template>
  <router-view />
  <FotterComponent />
</template>

<script lang="ts">
import FotterComponent from "./components/FotterComponent.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    FotterComponent,
  },
})
export default class App extends Vue {}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

.scrollable-content {
  height: calc(100vh - 80px);
  overflow-y: auto;
}

/* .container {
  max-width: 100% !important;
  width: 100% !important;
  margin-left: 100px !important;
  margin-right: 100px !important;
} */
</style>
