import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { authGuard } from "../store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/LoginPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/auth/RegisterPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/conditions-utilisation",
    name: "conditions-utilisation",
    component: () => import("../views/auth/RegisterPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("../views/auth/RegisterPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/change-password",
    name: "change-password",
    component: () => import("../views/auth/ChangePassword.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/dashboard/DashboardPage.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomePage.vue"),
    beforeEnter: authGuard,
    meta: {
      permissions: [
        "read_fournisseurs",
        "read_p_services",
        "read_p_tces",
        "read_p_travaux",
      ],
    },
  },
  {
    path: "/search",
    name: "search",
    component: () => import("../views/SearchPage.vue"),
    beforeEnter: authGuard,
    meta: {
      permissions: [
        "read_fournisseurs",
        "read_p_services",
        "read_p_tces",
        "read_p_travaux",
      ],
    },
  },
  {
    path: "/product/:id",
    name: "product",
    component: () => import("../views/products/ProductPage.vue"),
    beforeEnter: authGuard,
    meta: {
      permissions: ["read_fournisseurs"],
    },
  },
  {
    path: "/fournisseur/:id",
    name: "fournisseur",
    component: () => import("../views/fournisseur/ProfilePage.vue"),
    beforeEnter: authGuard,
    meta: {
      permissions: ["read_fournisseurs"],
    },
  },
  {
    path: "/p-service/:id",
    name: "p-service",
    component: () => import("../views/p-service/ProfilePage.vue"),
    beforeEnter: authGuard,
    meta: {
      permissions: ["read_p_services"],
    },
  },
  {
    path: "/p-travaux/:id",
    name: "p-travaux",
    component: () => import("../views/p-travaux/ProfilePage.vue"),
    beforeEnter: authGuard,
    meta: {
      permissions: ["read_p_travaux"],
    },
  },
  {
    path: "/p-tce/:id",
    name: "p-tce",
    component: () => import("../views/p-tce/ProfilePage.vue"),
    beforeEnter: authGuard,
    meta: {
      permissions: ["read_p_tces"],
    },
  },
  {
    path: "/chat/:contactId?",
    name: "chat",
    component: () => import("../views/ChatPage.vue"),
    props: true,
    beforeEnter: authGuard,
    meta: { permissions: ["chat"] },
  },
  {
    path: "/appels-offres",
    name: "appels-offres",
    component: () => import("../views/appels-offres/HomePage.vue"),
    props: true,
    beforeEnter: authGuard,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
