import axios from "axios";
import store from "@/store";

const backendUrl = `${process.env.VUE_APP_BACKEND_URL}`;

const baseURL = `${backendUrl}/api`;

const axiosInstance = axios.create({ baseURL });

import router from "@/router"; // Import your router instance

axiosInstance.interceptors.request.use((config) => {
  const token = store.state.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.Accept = "application/json";
  return config;
});

// ❌
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401 && store.state.isAuthenticated) {
      router.push("/");
      console.error("log out");
      await store.dispatch("logoutWithoutAPI"); // Call logout action from the store
    }
    return Promise.reject(error);
  }
);

// ✅ ✅
const authAPI = {
  login: (credentials: any) => axiosInstance.post("login", credentials),
  register: (credentials: any) => axiosInstance.post("register", credentials),
  logout: () => axiosInstance.post("user/logout"),
  firstLogin: () => axiosInstance.get("user/first_login"),
  changePassword: (data: any) =>
    axiosInstance.post("user/change_password", data),
  profil: () => axiosInstance.get("user/profil"),
};

// <SALLE DES MARCHES>
const appelsOffresPriveAPI = {
  getAll: () => axiosInstance.get("appels_offres_prive"),
  search: (params: any) =>
    axiosInstance.get("appels_offres_prive/search", { params }),

  add: (data: any) =>
    axiosInstance.post("appels_offres_prive", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id: number, data: any) =>
    axiosInstance.post(`appels_offres_prive/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id: number) => axiosInstance.delete(`appels_offres_prive/${id}`),

  // submit
  addSubmittion: (data: any) =>
    axiosInstance.post("appels_offres_prive/submittion", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  updateSubmittion: (id: number, data: any) =>
    axiosInstance.post(`appels_offres_prive/submittion/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteSubmittion: (id: number) =>
    axiosInstance.delete(`appels_offres_prive/submittion/${id}`),
};

const appelsOffresPublicAPI = {
  getAll: () => axiosInstance.get("appels_offres_public"),
  getSemiPublic: () => axiosInstance.get("appels_offres_public/semi_public"),
  search: (params: any) =>
    axiosInstance.get("appels_offres_public/search", { params }),

  add: (data: any) =>
    axiosInstance.post("appels_offres_public", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id: number, data: any) =>
    axiosInstance.post(`appels_offres_public/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id: number) => axiosInstance.delete(`appels_offres_public/${id}`),
};

const demandeGroupementAPI = {
  getAll: () => axiosInstance.get("demande_groupement"),

  add: (data: any) =>
    axiosInstance.post("demande_groupement", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id: number, data: any) =>
    axiosInstance.post(`demande_groupement/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id: number) => axiosInstance.delete(`demande_groupement/${id}`),
};

const sousTraitanceAPI = {
  getAll: () => axiosInstance.get("sous_traitance"),

  add: (data: any) =>
    axiosInstance.post("sous_traitance", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id: number, data: any) =>
    axiosInstance.post(`sous_traitance/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id: number) => axiosInstance.delete(`sous_traitance/${id}`),
};

const venteEnchereAPI = {
  getAll: () => axiosInstance.get("vente_enchere"),

  add: (data: any) =>
    axiosInstance.post("vente_enchere", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id: number, data: any) =>
    axiosInstance.post(`vente_enchere/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id: number) => axiosInstance.delete(`vente_enchere/${id}`),
};

const venteEnginAPI = {
  getAll: () => axiosInstance.get("vente_engin"),

  add: (data: any) =>
    axiosInstance.post("vente_engin", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id: number, data: any) =>
    axiosInstance.post(`vente_engin/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id: number) => axiosInstance.delete(`vente_engin/${id}`),
};

const venteEntrepriseAPI = {
  getAll: () => axiosInstance.get("vente_entreprise"),

  add: (data: any) =>
    axiosInstance.post("vente_entreprise", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id: number, data: any) =>
    axiosInstance.post(`vente_entreprise/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id: number) => axiosInstance.delete(`vente_entreprise/${id}`),
};
// </SALLE DES MARCHES>

// Achat
const achatsAPI = {
  getPromoteurs: () => axiosInstance.get("achats/promoteur"),

  // getPromoteurs: () => axiosInstance.get(""),
  addByPromoteur: (data: any) =>
    axiosInstance.post("achats/promoteur", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  getPromoteurAchatById: (id: number) =>
    axiosInstance.get(`achats/promoteur/${id}`),
  updateByPromoteur: (id: number, data: any) =>
    axiosInstance.post(`produits/fournisseur/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteByPromoteur: (id: number) =>
    axiosInstance.delete(`achats/promoteur/${id}`),
  // seller
  getSeller: () => axiosInstance.get("achats/seller"),
  updateStatusBySeller: (id: number, data: any) =>
    axiosInstance.post(`achats/seller/${id}/status`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  // admin
  getAdmin: () => axiosInstance.get("achats/admin"),
  updateStatusByAdmin: (id: number, data: any) =>
    axiosInstance.post(`achats/admin/${id}/status`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
};

// ✅ ✅
const contactsAPI = {
  getAll: () => axiosInstance.get("contacts"),
  add: (data: any) => axiosInstance.post("contacts", data),
};

// ✅ ✅
const countriesAPI = {
  getAll: () => axiosInstance.get("countries"),
  topWithMostProducts: () =>
    axiosInstance.get("countries/top_with_most_products"),
  add: (data: any) => axiosInstance.post("countries", data),
  update: (id: number, data: any) =>
    axiosInstance.post(`countries/update/${id}`, data),
  delete: (id: number) => axiosInstance.delete(`countries/${id}`),
};

// ✅ ✅
const fournisseursAPI = {
  getAll: () => axiosInstance.get("fournisseurs"),
  withCategory: () => axiosInstance.get("fournisseurs/with_category"),
  search: (params: any) => axiosInstance.get("fournisseurs/search", { params }),
  getById: (id: number) => axiosInstance.get(`fournisseurs/${id}`),
  add: (data: any) => axiosInstance.post("fournisseurs", data),
  update: (id: number, data: any) =>
    axiosInstance.post(`fournisseurs/update/${id}`, data),
  delete: (id: number) => axiosInstance.delete(`fournisseurs/${id}`),
};

// ✅ ✅
const messagesAPI = {
  send: (contactId: number, data: any) =>
    axiosInstance.post(`messages/send/${contactId}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  getByContact: (contactId: number) =>
    axiosInstance.get(`messages/${contactId}`),
};

// ✅ ✅
const pServicesAPI = {
  getAll: () => axiosInstance.get("p_services"),
  search: (params: any) => axiosInstance.get("p_services/search", { params }),
  getById: (id: number) => axiosInstance.get(`p_services/${id}`),
  add: (data: any) => axiosInstance.post("p_services", data),
  update: (id: number, data: any) =>
    axiosInstance.post(`p_services/update/${id}`, data),
  delete: (id: number) => axiosInstance.delete(`p_services/${id}`),
};

// ✅ ✅
const pTcesAPI = {
  getAll: () => axiosInstance.get("p_tces"),
  getById: (id: number) => axiosInstance.get(`p_tces/${id}`),
  add: (data: any) => axiosInstance.post("p_tces", data),
  update: (id: number, data: any) =>
    axiosInstance.post(`p_tces/update/${id}`, data),
  delete: (id: number) => axiosInstance.delete(`p_tces/${id}`),
};

// ✅ ✅
const pTravauxAPI = {
  getAll: () => axiosInstance.get("p_travaux"),
  search: (params: any) => axiosInstance.get("p_travaux/search", { params }),
  getById: (id: number) => axiosInstance.get(`p_travaux/${id}`),
  add: (data: any) => axiosInstance.post("p_travaux", data),
  update: (id: number, data: any) =>
    axiosInstance.post(`p_travaux/update/${id}`, data),
  delete: (id: number) => axiosInstance.delete(`p_travaux/${id}`),
};

// ✅ ✅
const permissionsAPI = {
  getAll: () => axiosInstance.get("permissions"),
  add: (data: any) => axiosInstance.post("permissions", data),
  update: (id: number, data: any) =>
    axiosInstance.post(`permissions/update/${id}`, data),
  delete: (id: number) => axiosInstance.delete(`permissions/${id}`),
  assignToRole: (id: number, data: any) =>
    axiosInstance.post(`permissions/${id}/assign_to_role`, data),
  removeFromRole: (id: number, data: any) =>
    axiosInstance.post(`permissions/${id}/remove_from_role`, data),
};

// ✅ ✅
const produitCategoriesAPI = {
  getAll: () => axiosInstance.get("produit_categories"),
  add: (data: any) => axiosInstance.post("produit_categories", data),
  update: (id: number, data: any) =>
    axiosInstance.post(`produit_categories/update/${id}`, data),
  delete: (id: number) => axiosInstance.delete(`produit_categories/${id}`),
};

// ✅ ✅
const produitsAPI = {
  getAll: () => axiosInstance.get("produits"),
  search: (params: any) => axiosInstance.get("produits/search", { params }),
  latest: () => axiosInstance.get("produits/latest"),
  getById: (id: number) => axiosInstance.get(`produits/${id}`),
  getByFournisseur: () => axiosInstance.get("produits/fournisseur"),
  getByFournisseurById: (id: number) =>
    axiosInstance.get(`produits/fournisseur/${id}`),

  add: (data: any) =>
    axiosInstance.post("produits/fournisseur", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id: number, data: any) =>
    axiosInstance.post(`produits/fournisseur/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  delete: (id: number) => axiosInstance.delete(`produits/fournisseur/${id}`),
};

// ✅ ✅
const projectsAPI = {
  getBySeller: () => axiosInstance.get("projects/seller"),
  addBySeller: (data: any) =>
    axiosInstance.post("projects/seller", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  updateBySeller: (id: number, data: any) =>
    axiosInstance.post(`projects/seller/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deleteBySeller: (id: number) => axiosInstance.delete(`projects/seller/${id}`),
};

// ✅ ✅
const rolesAPI = {
  getAll: () => axiosInstance.get("roles"),
  add: (data: any) => axiosInstance.post("roles", data),
  update: (id: number, data: any) =>
    axiosInstance.post(`roles/update/${id}`, data),
  delete: (id: number) => axiosInstance.delete(`roles/${id}`),
  getUsers: (roleId: number) => axiosInstance.get(`roles/${roleId}/users`),
  assignToUser: (roleId: number, data: any) =>
    axiosInstance.post(`roles/${roleId}/attach_user`, data),
  removeFromUser: (roleId: number, data: any) =>
    axiosInstance.post(`roles/${roleId}/detach_user`, data),
  getUserRole: () => axiosInstance.get("roles/user_role"),
};

// ✅ ✅
const roleDemandsAPI = {
  getAll: () => axiosInstance.get("role_demands"),
  approve: (id: number) => axiosInstance.patch(`role_demands/${id}/approve`),
};

// ✅ ✅
const servicesAPI = {
  getAll: () => axiosInstance.get("services"),
  add: (data: any) => axiosInstance.post("services", data),
  update: (id: number, data: any) =>
    axiosInstance.post(`services/update/${id}`, data),
  delete: (id: number) => axiosInstance.delete(`services/${id}`),
  getCurrentPsServices: () => axiosInstance.get("services/p_service"),
  addPServices: (data: any) => axiosInstance.post("services/p_service", data),
  deletePService: (id: number) =>
    axiosInstance.delete(`services/p_service/${id}`),
};

// ✅ ✅
const travauxCategoriesAPI = {
  getAll: () => axiosInstance.get("travaux_categories"),
  getChildrenCategories: () =>
    axiosInstance.get("travaux_categories/childrens"),
  add: (data: any) => axiosInstance.post("travaux_categories", data),
  update: (id: number, data: any) =>
    axiosInstance.post(`travaux_categories/update/${id}`, data),
  delete: (id: number) => axiosInstance.delete(`travaux_categories/${id}`),
  getCurrentPTCategories: () =>
    axiosInstance.get("travaux_categories/p_travau"),

  // addCategories: (categories: any) =>
  //   axiosInstance.post("travaux-categories/add-categories", { categories }),

  addPTCategories: (categories: any) =>
    axiosInstance.post("travaux_categories/p_travau", { categories }),
  deletePTCategory: (id: number) =>
    axiosInstance.delete(`travaux_categories/p_travau/${id}`),
};

// ✅ ✅
const usersAPI = {
  getAll: () => axiosInstance.get("users"),
  add: (data: any) => axiosInstance.post("users", data),
  update: (id: number, data: any) =>
    axiosInstance.post(`users/update/${id}`, data),
  delete: (id: number) => axiosInstance.delete(`users/${id}`),
};

// ✅ ✅
const videosAPI = {
  getAll: () => axiosInstance.get("videos"),
  add: (data: any) =>
    axiosInstance.post("videos", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  update: (id: number, data: any) =>
    axiosInstance.post(`videos/update/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  delete: (id: number) => axiosInstance.delete(`videos/${id}`),
};

export default {
  achatsAPI,
  authAPI,
  contactsAPI,
  countriesAPI,
  fournisseursAPI,
  messagesAPI,
  pServicesAPI,
  pTcesAPI,
  pTravauxAPI,
  permissionsAPI,
  produitCategoriesAPI,
  produitsAPI,
  projectsAPI,
  rolesAPI,
  roleDemandsAPI,
  servicesAPI,
  travauxCategoriesAPI,
  usersAPI,
  videosAPI,

  // SALLES DES MARCHES
  appelsOffresPriveAPI,
  appelsOffresPublicAPI,
  demandeGroupementAPI,
  sousTraitanceAPI,
  venteEnchereAPI,
  venteEnginAPI,
  venteEntrepriseAPI,
};
