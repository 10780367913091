import { createStore } from "vuex";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import Cookies from "js-cookie";

import apiServices from "../tools/apiServices";

interface RegisterPayload {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}

const store = createStore({
  state: {
    isAuthenticated: !!Cookies.get("ght_token"),
    user: JSON.parse(Cookies.get("user") || "{}"),
    token: Cookies.get("ght_token"),
    roles: JSON.parse(Cookies.get("ght_user_roles") || "[]"),
    permissions: JSON.parse(Cookies.get("ght_user_permissions") || "[]"),
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    user: (state) => state.user,
    roles: (state) => state.roles,
    permissions: (state) => state.permissions,
  },
  mutations: {
    SET_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },
  },
  actions: {
    async login({ commit }, { responseData }) {
      try {
        console.log(responseData);
        // Set the token and user information in cookies with an expiry date
        const expiryDate = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000 // 24 hours from now
        );

        Cookies.set("ght_token", responseData.token, { expires: expiryDate });
        Cookies.set("user", JSON.stringify(responseData.user), {
          expires: expiryDate,
        });
        Cookies.set("ght_user_roles", JSON.stringify(responseData.roles), {
          expires: expiryDate,
        });
        Cookies.set(
          "ght_user_permissions",
          JSON.stringify(responseData.permissions),
          {
            expires: expiryDate,
          }
        );

        // Commit the necessary mutations
        commit("SET_TOKEN", responseData.token);
        commit("SET_AUTHENTICATED", true);
        commit("SET_USER", responseData.user);
        commit("SET_ROLES", responseData.roles);
        commit("SET_PERMISSIONS", responseData.permissions);
      } catch (error) {
        console.error("Login failed:", error);
        throw error;
      }
    },
    async logout({ commit }) {
      try {
        const response = await apiServices.authAPI.logout();

        // Check if the response is successful
        if (response.status !== 200) {
          throw new Error("Logout failed");
        }

        // Perform post-logout actions
        Cookies.remove("ght_token");
        Cookies.remove("user");
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", {});
        commit("SET_TOKEN", null);
        commit("SET_ROLES", []);
        commit("SET_PERMISSIONS", []);
        window.location.reload();
      } catch (error) {
        console.error("Logout failed:", error);
        throw error;
      }
    },
    async logoutWithoutAPI({ commit }) {
      try {
        // Perform post-logout actions
        Cookies.remove("ght_token");
        Cookies.remove("user");
        commit("SET_AUTHENTICATED", false);
        commit("SET_USER", {});
        commit("SET_TOKEN", null);
        commit("SET_ROLES", []);
        commit("SET_PERMISSIONS", []);
        window.location.reload();
      } catch (error) {
        console.error("Logout failed:", error);
        throw error;
      }
    },
  },
  modules: {},
});

export default store;

export const authGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  const unauthRoutes = [
    "login",
    "register",
    "forget-password",
    "conditions-utilisation",
  ];

  const isAuthenticated = store.getters.isAuthenticated;
  const userPermissions =
    store.getters.permissions.map((permission: any) => permission.name) || []; // Fetch user permissions
  // const userPermissionNames = userPermissions.map(
  //   (permission: any) => permission.name
  // );
  const routeName = (to.name ?? "").toString();
  // const routePermissions = to.meta.permissions || [];
  const routePermissions = Array.isArray(to.meta.permissions)
    ? to.meta.permissions
    : [];

  console.log(userPermissions);
  console.log(routePermissions);
  if (isAuthenticated) {
    if (unauthRoutes.includes(routeName)) {
      next({ name: "home" });
    } else {
      if (routePermissions.length === 0) {
        // No permissions required
        next();
      } else {
        // Allow access if the user has at least one required permission
        const hasPermission = routePermissions.some((permission) =>
          userPermissions.includes(permission)
        );

        console.log(hasPermission);

        if (hasPermission) {
          next();
        } else {
          if (to.name == "home") {
            next({ name: "dashboard" });
          } else {
            next({ name: "home" }); // Redirect to an unauthorized page or fallback
          }
        }
      }
    }
  } else {
    if (!unauthRoutes.includes(routeName)) {
      next({ name: "login" });
    } else {
      next();
    }
  }
};

// export const authGuard = (
//   to: RouteLocationNormalized,
//   from: RouteLocationNormalized,
//   next: NavigationGuardNext
// ) => {
//   const isAuthenticated = store.getters.isAuthenticated;
//   const userPermissions = store.getters.userPermissions || []; // Fetch user permissions
//   const unauthRoutes = [
//     "login",
//     "register",
//     "forget-password",
//     "conditions-utilisation",
//   ];
//   const routeName = (to.name ?? "").toString();

//   // Required permissions for the route
//   const routePermissions = to.meta.permissions || [];

//   if (isAuthenticated) {
//     if (unauthRoutes.includes(routeName)) {
//       next({ name: "home" });
//     } else {
//       if (routePermissions.length === 0) {
//         // No permissions required
//         next();
//       } else {
//         // Allow access if the user has at least one required permission
//         const hasPermission = routePermissions.some((permission) =>
//           userPermissions.includes(permission)
//         );

//         if (hasPermission) {
//           next();
//         } else {
//           next({ name: "unauthorized" }); // Redirect to an unauthorized page or fallback
//         }
//       }
//     }
//   } else {
//     if (!unauthRoutes.includes(routeName)) {
//       next({ name: "login" }); // Redirect unauthenticated users to login
//     } else {
//       next();
//     }
//   }
// };
