import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import fr from "./locales/fr.json";

const messages = {
  en,
  fr,
};

const savedLocale = localStorage.getItem("locale") || "en";

const i18n = createI18n({
  locale: savedLocale,
  fallbackLocale: "en", // fallback locale if translation is missing
  messages, // set locale messages
});

export default i18n;
